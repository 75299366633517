<template>
  <main class="container">
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <!--CONFIRM DELETE DIALOG-->
    <div
      class="modal modal__confirm show"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm_delete ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmDelete()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Access Role
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmDelete()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="deleteItem()"
                >
                  {{ process ? "Processing..." : "Delete" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal
      :show="!userCanViewRoles"
      :title="'Unauthorized'"
      @close="goToDashboard"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Unauthorized
            </h5>
            <!-- <h5 class="text-danger">
              {{ error }}
            </h5> -->
            <div class="d-flex mt-5">

              <button
                data-dismiss="#confirmModal"
                class="
                  button
                  form__button form__button--lg
                  save-changes
                  fitrem
                "
                @click.prevent="goToDashboard"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <CreateRole
      v-bind:show="showCreateModal"
      @close="closeCreateModal"
      @alertSuccess="alertSuccess"
      @reload="getAllRole"
      @alertWarning="alertWarning"
      @alertError="alertError"
      :getType="getType"
      :accessRightP="accessRight"
      :permissionP="permission"
    />

    <EditRole
      v-bind:show="showEditModal"
      v-bind:close="closeEditModal"
      @close="closeEditModal"
      @alertSuccess="alertSuccess"
      @alertWarning="alertWarning"
      @alertError="alertError"
      @reload="getAllRole"
      :getType="getType"
      :accessRightP="accessRight"
      :permissionP="permission"
      v-bind:singleRole="singleRole"
      v-if="checkPayloadValid(singleRole)"
    />

    <ContentHeader
      v-bind:title="content_header.title"
    />

    <div class="page__body">
      <div class="page__body__header">
        <div class="row align-items-center justify-content-end">
          <div style="margin-right: 20px">
            <button
              type="button"
              class="
                w-100
                button
                page__body__header__button
                button--grey
                text--capital
              "
              @click="toggleFilterModal"
            >
              <span class="d-inline-flex mr-2">filter</span>
            </button>
          </div>
          <div style="margin-right: 20px">
            <button
              class="
                button button--grey
                text--capital text--small
                d-flex
                align-items-center
              "
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              export
              <span class="dropdown__icon">
                <ion-icon name="caret-down-outline"></ion-icon>
              </span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                class="dropdown-item text--capital"
                href="javascript:void(0)"
                v-if="userCanExportToPDF"
                @click="pdfDownload"
                >PDF</a
              >
              <a
                class="dropdown-item text--capital"
                href="javascript:void(0)"
                v-if="userCanExportToExcel"
                @click="processDownload"
                >excel</a
              >
            </div>
          </div>
          <div v-if="userCanCreateRoles" style="margin-right: 20px">
            <button
              @click="openCreateModal()"
              type="button"
              class="
                button
                page__body__header__button
                button--black
                text--capital
                ml-auto
              "
            >
              <span class="d-inline-flex mr-2">add roles</span>
              <ion-icon name="add-outline" class="text--regular"></ion-icon>
            </button>
          </div>
        </div>
      </div>
      <hr style="border: 1px solid #e5e5e5" />
      <div class="row mt-5">
        <div
          class="col-7"
          style="display:flex; width:50%; font-size:14px"
          v-if="filterText"
        >
          <div style="width:80%">
            {{ filterText }}
          </div>
          <div style="display:flex;width:10%">
            <span style="cursor:pointer;font-size:17px" @click="editFilter">
              <i style="padding-right:5px" class="bi bi-pencil-square"></i>
            </span>
            <span style="cursor:pointer;font-size:17px" @click="deleteFilter">
              <i style="padding-left:5px" class="bi bi-x-circle-fill"></i>
            </span>
          </div>
        </div>

        <div class="col-5">
          <div class="form__item">
            <input
              type="text"
              class="input form__input form__input--lg"
              v-model="searchRoleName"
              @input="performSearch"
              id="searchProduct"
              placeholder="Search for Role Name"
              style="height:40px"
            />
          </div>
        </div>
      </div>

      <table class="customtable w-100">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 50%"
            >
              roles
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 39%"
            >
              users
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 11%"
            >
              actions
            </th>
          </tr>
        </thead>
        <tbody v-if="!userCanViewRoles">
          <tr>
            <td colspan="12">
              <div
                class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                  p5
                "
              >
                <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                <span
                  class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >You do not have view permission </span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="loading">
          <tr>
            <td colspan="12">
              <div class="d-flex align-items-center justify-content-center p5">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span
                  class="text--black text--600 text--medium d-inline-flex ml-4"
                  >Please wait</span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="allRole && allRole.data && allRole.data.length">
          <tr v-for="(row, ri) in allRole.data" v-bind:key="ri">
            <td style="padding-right: 15px !important;">
              <span class="table__body__text table__text text--capital">{{
                row.roleName
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                row.user
              }}</span>
            </td>
            <td>
              <b-dropdown text="Action" class="m-2 dropdown--button-grey">
                <b-dropdown-item href="#" @click="openEditModal(row)" v-if="userCanEditRoles"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  v-if="userCanDeleteRoles"
                  @click="confirmDelete({ roleKey: row.roleKey })"
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="pagination"
        v-if="allRole && allRole.data && allRole.data.length && !loading"
      >
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            showing
            <span class="d-inline-block text--primary">{{
              allRole ? allRole.currentPage : 0
            }}</span>
            to
            <span class="d-inline-block text--primary">{{
              allRole ? allRole.totalPages : 0
            }}</span>
            of
            <span>{{ allRole ? allRole.totalCount : 0 }}</span>
          </div>
          <div
            class="
              pagination__buttons
              d-flex
              align-items-center
              justify-content-between
            "
            v-if="allRole"
          >
            <button
              class="button button--sm text--regular"
              @click="changePage('previous')"
              :disabled="!allRole.hasPrevious"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <button
              v-for="page in allRole.totalPages"
              :key="page"
              :class="{
                'text--white button--primary ': allRole.currentPage === page,
              }"
              class="button text--regular button--sm ml-2"
              @click="changePage(page)"
            >
              {{ page }}
            </button>
            <button
              class="button text--regular button--sm ml-2"
              @click="changePage('next')"
              :disabled="!allRole.hasNext"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal />
    <CreateFilter
      :show="showFilterModal"
      @close="toggleFilterModal"
      @send-activate-filter="sendFilter"
      @reload="reloadFilter"
      @alertError="alertError"
      :filterEditValue="filterEdit"
      :showEditedValue="showEditedValue"
      :filterHeaderName="'Role'"
      filterCategory="role"
    />
  </main>
</template>

<script>
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { mapState } from "vuex";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
import CreateRole from "./Roles/create.vue";
import EditRole from "./Roles/edit.vue";
import {
  GET_ALL_ROLE,
  GET_ROLE,
  GET_TYPE,
  GET_ACCESS_RIGHT,
  GET_PERMISSION,
  GET_ALL_ROLE_EXPORTS,
} from "@/core/services/store/types";
import ApiService from "../../../core/services/access.service";
import { Role } from "@/core/services/role.js";

export default {
  name: "Roles",

  data: function () {
    return {
      page: 1,
      size: 10,
      status: true,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Role has been created",
      },
      fetchModel: {
        filters: [],
        pageIndex: 1,
        pageSize: 10,
        isFromFilter: false,
        filterCategory: "",
        searchRoleName: "",
      },
      confirm_delete: false,
      process: false,
      showCreateModal: false,
      showEditModal: false,
      searchRoleName: "",
      content_header: {
        title: "User role",
        subTitle:
          "Manage the products offerings that you have available to your customers by creating and editing loan.",
      },
      filterText: "",
      filterEdit: [],
      showEditedValue: 0,
      showFilterModal: false,
      rolePermissions: [
        "is_support",
        "is_administrator",
        "is_credit_officer",
        "is_teller",
        "is_delete",
      ],
    };
  },
  watch: {
    allRole() {
      this.filterText = this.allRole.filterText ? this.allRole.filterText.replace(/_/g, " ") : "";
      this.filterEdit = this.allRole.filters ? this.allRole.filters : [];
      if (this.allRole.filterText && this.allRole.filters) {
        this.getExportData();
      }
    },
  },
  methods: {
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    sendFilter(value) {
      const filterArray = value.map((obj) => {
        const payload = {};

        payload.filterFieldName = obj.filterName;
        payload.filterFieldOperator = obj.filterOp;
        if (obj.value1 === '' || obj.value1 === null) {
          if (this.rolePermissions.includes(obj.filterName)) {
            const val = obj.value == "True" ? "1" : "0";
            payload.filterFieldValues = [val];
          } else payload.filterFieldValues = [obj.value];

          return payload;
        }
        payload.filterFieldValues = [obj.value, obj.value1];
        return payload;
      });
      this.fetchModel.filters = filterArray;
      this.fetchModel.pageIndex = 1;
      this.fetchModel.pageSize = 10;
      this.fetchModel.filterCategory = "role";
      this.fetchModel.searchRoleName = "";
      this.fetchModel.isFromFilter = true;
      this.$store.dispatch(GET_ALL_ROLE, this.fetchModel)
      .then(() => {
        this.alertSuccess("Successfully applied filter");
      })
      .catch((error) => {
        this.alertError(error.response.data.message);
      });
    },
    getAllRole(page = null) {
      this.fetchModel.pageIndex = page
        ? page
        : this.fetchModel.pageIndex;

      this.$store.dispatch(GET_ALL_ROLE, this.fetchModel)
      .catch((error) => {
        this.alertError(error.response.data.message);
      });

      if( !this.userCanViewRoles ) return this.alertWarning('You don not have permission to view this page');
    },
    reloadFilter() {
      this.deleteFilter();
      this.fetchModel.isFromFilter = false;
      this.fetchModel.filterCategory = "";
      this.fetchModel.filters = [];
      this.fetchModel.pageIndex = 1;
      this.fetchModel.pageSize = 10;
      this.getAllRole();
    },
    deleteFilter() {
      this.filterText = "";
      this.filterEdit = [];
    },
    editFilter() {
      this.showEditedValue = Math.random();
      this.toggleFilterModal();
    },
    getExportData() {
      if (this.allRole.totalCount > 0) {
        const data = {
          pageSize: this.allRole.totalCount,
          filters: this.fetchModel.filters,
          filterCategory: this.fetchModel.filterCategory,
          pageIndex: this.fetchModel.pageIndex,
          isFromFilter: this.fetchModel.isFromFilter,
        };
        this.$store.dispatch(GET_ALL_ROLE_EXPORTS, data).then(() => {
          this.alertSuccess('Successfully filter role')
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
      }
    },
    getAccessRight() {
      this.$store.dispatch(GET_ACCESS_RIGHT);
    },
    getGetType() {
      this.$store.dispatch(GET_TYPE);
    },
    getPermission() {
      this.$store.dispatch(GET_PERMISSION);
    },
    performSearch() {
      this.fetchModel.searchRoleName = this.searchRoleName;
      this.fetchModel.isFromFilter = false;
      this.fetchModel.filterCategory = "";
      this.fetchModel.filters = [];
      this.$store.dispatch(GET_ALL_ROLE, this.fetchModel);
    },
    changePage(page = null) {
      if (page === "next") {
        const nextPage = this.allRole.hasNext
          ? this.allRole.currentPage + 1
          : 1;
        this.fetchModel.pageIndex = nextPage;
        this.$store.dispatch(GET_ALL_ROLE, this.fetchModel);
      } else if (page === "previous") {
        const nextPage = this.allRole.hasPrevious
          ? this.allRole.currentPage - 1
          : 1;
        this.fetchModel.pageIndex = nextPage;
        this.$store.dispatch(GET_ALL_ROLE, this.fetchModel);
      } else if (page !== null) {
        this.fetchModel.pageIndex = page;
        this.$store.dispatch(GET_ALL_ROLE, this.fetchModel);
      }
    },
    confirmDelete(row) {
      if (row) this.roleKey = row.roleKey;

      this.alert.show = false;
      if (!this.confirm_delete) {
        this.confirm_delete = true;
      } else {
        this.confirm_delete = false;
      }
    },
    deleteItem() {
      this.process = true;
      ApiService.put(
        `RoleManagement/DeleteRoles/roleKey/${
          this.roleKey
        }`
      )
        .then((res) => {
          this.process = false;
          this.confirm_delete = false;
          if (res.data.status) {
            this.getAllRole();
            this.alertSuccess(res.data.message);
          } else {
            this.alertError(res.data.message);
          }
        })
        .catch((e) => {
          this.confirm_delete = false;
          try {
            this.process = false;
            if(e.response.status == 409) {
              this.alertWarning(e.response.data);
            } else {
              this.alertError(e.response.data.message);
            }
          } catch (e) {
            this.alertError(
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Roles List Download",
        Subject: "Roles List Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("Roles");

      let sheetData = [[
        "Role Name",
        "Number of Users",
      ]];
      if (this.allExports && this.allExports.data) {
        this.allExports.data.map((item) => {
          let new_arr = [];
          new_arr.push(item.roleName);
          new_arr.push(item.user);

          sheetData.push(new_arr);
        });
      }

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["Roles"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },
    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },
    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "roles.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    pdfDownload() {
      const columns = [
        { title: "Role Name", dataKey: "name" },
        { title: "Number of Users", dataKey: "users" },
      ];

      let doc = new jsPDF("l", "pt", "a4");
      doc.setFontSize(6);
      doc.autoTable(columns, this.download);
      doc.save("roles.pdf");
    },
    checkPayloadValid(ob) {
      if (Object.keys(ob).length == 0) {
        return false;
      }
      return true;
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    openCreateModal() {
      this.showCreateModal = true;
    },
    openEditModal(row = null) {
      this.role = row;
      this.$store.dispatch(GET_ROLE, row.roleKey);
      this.showEditModal = true;
    },
    closeCreateModal() {
      this.showCreateModal = false;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
    goToDashboard() {
      this.$router.push({ path: '/dashboard' });
    },
  },
  components: {
    DeleteModal,
    CreateRole,
    EditRole,
    CreateFilter: () =>
      import(
        /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
      ),
  },
  computed: {
    ...mapState({
      singleRole: (state) => state.access?.role?.data || {},
      allRole: (state) => state.access?.allRole || [],
      allExports: (state) => state.access?.allRoleExports || [],
      getType: (state) => state.access?.getType?.data || [],
      accessRight: (state) => state.access?.accessRight?.data || [],
      permission: (state) => {
        return state.access.permission.data ? state.access.permission.data : []
      },
      loading: (state) => state.access.loadingRole,

      user: (state) => state.auth.user,
    }),
    download() {
      let data = [];
      if(this.allExports.data) {
        this.allExports.data.forEach((item) => {
          let new_obj = {};
          new_obj.name = item.roleName;
          new_obj.users = item.user;

          data.push(new_obj);
        });
      }

      return data;
    },
    userCanViewRoles() {
      const roles = this.user.scopes.find(item => item.scopeName === Role.Access);
      const { permissionsList } = roles;
      return permissionsList.CanViewRole;
    },
    userCanEditRoles() {
      const roles = this.user.scopes.find(item => item.scopeName === Role.Access);
      const { permissionsList } = roles;
      return permissionsList.CanEditRole;
    },
    userCanCreateRoles() {
      const roles = this.user.scopes.find(item => item.scopeName === Role.Access);
      const { permissionsList } = roles;
      return permissionsList.CanCreateRole;
    },
    userCanDeleteRoles() {
      const roles = this.user.scopes.find(item => item.scopeName === Role.Access);
      const { permissionsList } = roles;
      return permissionsList.CanDeleteRole;

    },
    userCanExportToExcel() {
      const roles = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = roles;
      return permissionsList.CanExportToExcel;
    },
    userCanExportToPDF() {
      const roles = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = roles;
      return permissionsList.CanDownloadDocument;
    },
  },
  mounted() {
    this.getAllRole();
    this.getGetType();
    this.getAccessRight();
    this.getPermission();
  },
};
</script>
